<template>
  <div>
    <v-carousel
        v-if="cargarCarousel"
        cycle
        height="400"
        hide-delimiter-background
        show-arrows-on-hover
    >
    <div class="text-right ma-2 d-flex justify-end alquiler-venta-chips" >
        <v-chip :elevation="4" class="mx-2" color="accent indigo--text" v-if="propiedad.alquiler">alquiler</v-chip>
        <v-chip :elevation="4" class="mx-2" color="accent indigo--text" v-if="propiedad.venta || !propiedad.alquiler">
          venta
        </v-chip>
    </div>

      <v-carousel-item
          v-for="(foto, i) in propiedad.fotos"
          :key="i"
          :src="foto.path"
          >

          <span class="estado rotate" v-if="propiedad.estado">
              {{ propiedad.estado.nombre }}
          </span>
      </v-carousel-item>
    </v-carousel>
    <v-img v-else :src="fotoPrincipal" >

      <span class="estado rotate" v-if="propiedad.estado">
          {{ propiedad.estado.nombre }}
      </span>
    </v-img>
  </div>
</template>

<script>
export default {
  props: ['propiedad'],
  computed: {
    cargarCarousel() {
      return this.propiedad.fotos && Array.isArray(this.propiedad.fotos) && this.propiedad.fotos.length > 1
    },
    fotoPrincipal() {
      if (this.propiedad.fotos && this.propiedad.fotos.length > 0) {
        return this.propiedad.fotos[0].path;
      } else {
        return '/img/mariocedrani_01.jpg';
      }
    }
  }
}
</script>

<style>
  .alquiler-venta-chips {
    position: absolute;
    z-index: 999;
    width: 96%;
    padding: 0 auto;
  }
</style>
