<template>
  <v-app>

    <v-app-bar
      app
      color="accent"
      flat
    >
      <v-row class="py-0 fill-height">
        <router-link :to="{ name: 'Inicio' }" class="fill-height">
          <v-img
            class="fill-height"
            src="/img/logo_Mario_Cedrani.png"
            contain
            />
        </router-link>
      </v-row>

      <v-spacer />

      <v-menu v-if="auth" left bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
              <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
          </template>

          <v-list>
              <v-list-item v-if="user && user.name" link>
                  <v-list-item-title >
                    <v-icon>mdi-account</v-icon>  {{ user.name }}
                  </v-list-item-title>
              </v-list-item>
              <v-list-item link :to="{ name: 'Administrar' }">
                  <v-list-item-title>
                    <v-icon>mdi-shield-crown</v-icon> Administrar
                  </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                  <v-list-item-title @click="cerrarSesion" >
                    <v-icon>mdi-logout</v-icon> Cerrar sesión
                  </v-list-item-title>
              </v-list-item>
          </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-toolbar v-if="!enZonaAdmin">
        <v-toolbar-items >
          <v-btn text  :to="{ name: 'Inicio' }" exact >Todo</v-btn>
          <v-btn text  :to="{ name: 'Categoria', params: { categoria: 1 } }" >Casas</v-btn>
          <v-btn text  :to="{ name: 'Categoria', params: { categoria: 2 } }" >Apartamentos</v-btn>
          <v-btn text  :to="{ name: 'Categoria', params: { categoria: 3 } }" >Locales Comerciales</v-btn>
          <v-btn text  :to="{ name: 'Categoria', params: { categoria: 4 } }" >Solares</v-btn>
          <v-btn text  :to="{ name: 'Categoria', params: { categoria: 5 } }" >Campos</v-btn>
          <v-btn text  :to="{ name: 'Nosotros' }">Nosotros</v-btn>
          <!-- <v-btn flat :to="{ name: 'Categoria', params: { categoria: 6 } }">Alquiler</v-btn>
          <v-btn flat :to="{ name: 'Categoria', params: { categoria: 7 } }">Contacto</v-btn>
           -->
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid>
        <!-- <Carousel v-show="verCarousel" /> -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer
      padless
    >
      <v-card
        flat
        tile
        width="100%"
        class="accent text-center"
      >
        <v-card-text>
          <v-icon class="mx-3 " size="16px">fas fa-home</v-icon> Asamblea 329, San José de Mayo
          <v-icon class="mx-3 " size="16px">fas fa-phone</v-icon> Tel.: 434 23108
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="">
          &copy;{{ new Date().getFullYear() }} — <strong>Inmobiliaria Mario Cedrani</strong>
        </v-card-text>
      </v-card>
    </v-footer>

    <v-snackbar
        dark
        v-model="snackbar.activo"
        :color="snackbar.tipo"
        :bottom="snackbar.y === 'bottom'"
        :left="snackbar.x === 'left'"
        :multi-line="snackbar.mode === 'multi-line'"
        :right="snackbar.x === 'right'"
        :timeout="snackbar.timeout"
        :top="snackbar.y === 'top'"
        :vertical="snackbar.mode === 'vertical'"
      >
      <v-card-title class="d-flex flex-row-reverse">
        <v-btn light icon @click="snackbar.activo = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-icon v-if="snackbar.icon">{{snackbar.icon}}</v-icon> {{ snackbar.texto }}
      </v-card-text>
    </v-snackbar>
  </v-app>
</template>

<script>
import Carousel from './components/Carousel'

export default {
  name: 'App',
  components: {
    Carousel
  },
  data () {
    return {
      verCarousel: false,
      socialIcons: [
        'fab fa-facebook',
        // 'fab fa-twitter',
        // 'fab fa-google-plus',
        // 'fab fa-linkedin',
        'fab fa-instagram'
      ]
    }
  },
  beforeMount() {
    this.$store.dispatch('estaLogueado', {
      ok() {
        // console.log('Logueado')
      },
      err() {
        // console.log('no Logueado')
      }
    })
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    user() {
      return this.$store.state.usuario;
    },
    enZonaAdmin () {
      // return this.$router.currentRoute.path.startsWith('/administrar/iniciar-sesion')
      return this.$route.name === 'IniciarSesion'
    },
    snackbar() {
      return this.$store.state.snackbar
    }
  },
  methods: {
    cerrarSesion() {
      let self = this
      this.$store.dispatch('logout', {
          ok() {
              self.$router.push({ name: 'Inicio', params: { nocheck: true  } });
          }
      });
    }
  },
  metaInfo: {
    title: 'San José',
    titleTemplate: '%s | Inmobiliaria Mario Cedrani'
  }
}
</script>
