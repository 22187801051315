import axios from 'axios'

let apiPath = '/api/propiedades'

export default {
    all() {
        return axios.get(`${apiPath}`)
    },
    show(id) {
        return axios.get(`${apiPath}/${id}`)
    }
}
