<template>
    <label :for="refString">
        <div class="drop"
            :class="getClasses"
            @dragover.prevent="dragOver"
            @dragleave.prevent="dragLeave"
            @drop.prevent="drop($event)">
                <v-avatar
                    class="ma-3"
                    :class="getAvatarClass()"
                    size="164"
                    tile
                >
                    <v-img
                        :src="imageSource || '/img/' + getPlaceHolder()"></v-img>
                </v-avatar>
            <!-- <img :src="imageSource" v-if="imageSource" />
            <h1 v-if="wrongFile">Wrong file type</h1>
            <h1 v-if="!imageSource && !isDragging && !wrongFile">Drop an image</h1> -->
            <div style="display: none;">
                <input :id="refString" type="file" :ref="refString" :accept="'image/*'" @change="requestUploadFile">
            </div>
        </div>
    </label>
</template>



<script>
export default {
    props: [
        'fotoUrl',
        'imagenIndex',
        'esAvatar',
        'esDocumento',
        'tipo'
    ],
  data(){
    return{
      isDragging:false,
      wrongFile:false,
      imageSource:null,
      file: null,
    }
  },
  created() {
      this.imageSource = this.fotoUrl
  },
  mounted() {
    //   this.index = this.imagenIndex
  },
  computed:{
    getClasses(){
      return {
          isDragging: this.isDragging,
          avatar: this.esAvatar
        }
    },
    refString() {
        return this.tipo + '_uploadfile_' + this.imagenIndex
    }
  },
  watch: {
      fotoUrl(url, oldUrl) {
          this.imageSource = this.fotoUrl
      }
  },
  methods:{
    getAvatarClass(){
        return {
          'imgAvatar': this.esAvatar
        }
    },
    getPlaceHolder() {
        if (this.esAvatar) {
            return 'avatar_placeholder.jpg'
        } else if (this.esDocumento) {
            return 'cargar_documento.png'
        } else {
            return 'cargar_imagen.png'
        }
    },
    dragOver(){
      this.isDragging = true
    },
    dragLeave(){
      this.isDragging = false
    },
    drop(e){
      let files = e.dataTransfer.files

      this.wrongFile = false

      // allows only 1 file
      if (files.length === 1) {

        this.file = files[0]


        // allows image only
        if (this.file.type.indexOf('image/') >= 0) {

          let reader = new FileReader()
          let MAX_WIDTH = 800;
          let MAX_HEIGHT = 800;

          reader.onload = f => {
            let image = document.createElement('img')
            image.onload = () => {
              let width = image.width;
              let height = image.height;

              // Change the resizing logic
              if (width > height) {
                  if (width > MAX_WIDTH) {
                      height = height * (MAX_WIDTH / width);
                      width = MAX_WIDTH;
                  }
              } else {
                  if (height > MAX_HEIGHT) {
                      width = width * (MAX_HEIGHT / height);
                      height = MAX_HEIGHT;
                  }
              }

              let canvas = document.createElement('canvas')
              canvas.height = height
              canvas.width = width
              let ctx = canvas.getContext('2d')
              ctx.drawImage(image, 0, 0, width, height)
                this.imageSource = canvas.toDataURL(this.file.type)
                this.$emit('nuevaImagen', this.imageSource, this.file.name, this.imagenIndex)
            }

            image.src = f.target.result
            // this.imageSource = f.target.result
            this.isDragging = false
          }
          reader.readAsDataURL(this.file)
        }else{
          this.wrongFile = true
          this.imageSource = null
          this.isDragging = false
        }
      }
    },
    onRequestUploadFiles(){

    },
    requestUploadFile(){
    //   var src = this.$el.querySelector('#uploadmyfile')
      let src = this.$refs[this.refString]
      this.drop({dataTransfer:src})
    }
  }
}
</script>



<style scoped>
.isDragging{
  background-color: #999;
  border-color: #fff;
}

.imgAvatar .v-image {
    border-radius: 50%
}
</style>
