<template>
  <v-container>
    <v-row>
      <v-toolbar color="accent lighten-1" dense class="mb-4">
          <v-toolbar-title >Administrar propiedades</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-text-field
            class="mr-4"
              name="busqueda"
              label="Búsqueda"
              v-model="busquedaDePropiedades"
              append-icon="mdi-magnify"
              single-line
              hide-details
          ></v-text-field>
          <v-toolbar-items>
            <v-btn text  :to="{ name: 'PropiedadCrear' }">
              <v-icon>mdi-plus</v-icon>
              Crear
            </v-btn>
          </v-toolbar-items>
      </v-toolbar>
    </v-row>

    <v-card>
        <v-data-table
            :headers="headersPropiedades"
            :items="propiedades"
            :items-per-page="5"
            class="elevation-1"
            :search="busquedaDePropiedades"
        >
          <template v-slot:[`item.publicada`]="{ item }" class="text-end">
            <v-switch disabled v-model="item.publicada" color="green" :value="item.publicada" />
          </template>

          <template v-slot:[`item.actions`]="{ item }" class="text-end">
                <v-icon
                    small
                    class="mr-2"
                    @click="$router.push({ name: 'Propiedad', params: { id: item.id } })"
                >
                    mdi-eye
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="$router.push({ name: 'PropiedadEditar', params: { id: item.id }})"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="propiedadEliminar = item"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-card>



    <confirmar-eliminar v-if="propiedadEliminar"
            :elementoEliminar="propiedadEliminar"
            :descripcion="`la propiedad '${propiedadEliminar.titulo}'.`"
            v-on:confirmarEliminar="eliminarPropiedad"
            v-on:cancelarEliminar="propiedadEliminar = null" />
  </v-container>
</template>

<script>
import propiedadesApi from '../../api/adminPropiedades'
import confirmarEliminar from './componentes/confirmarEliminar.vue'

export default {
  data() {
    return {
      busquedaDePropiedades: '',
      headersPropiedades: [
        { text: 'ID', value: 'id' },
        { text: 'Publicada', value: 'publicada' },
        { text: 'Titulo', value: 'titulo' },
        { text: 'Categoria', value: 'categoria.nombre' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      propiedades: [],
      propiedadEliminar: null,
    }
  },
  mounted() {
    this.obtenerPropiedades()
  },
  methods: {
    obtenerPropiedades() {
      propiedadesApi.all()
        .then(result => {
        this.propiedades = result.data
      })
      .catch(error => {
        console.error(error)
      })
    },
    eliminarPropiedad() {
      let propiedadTitulo = this.propiedadEliminar.titulo

      propiedadesApi.destroy(this.propiedadEliminar.id)
        .then(result => {
          this.propiedades = this.propiedades.filter(propiedad => {
            return propiedad.id !== this.propiedadEliminar.id
          })
          this.$store.commit("snackbar", {
              texto: `La propiedad "${propiedadTitulo}" fue eliminada con éxito.`,
              tipo: "success",
              icon: "mdi-information"
          });
          this.propiedadEliminar = null
        })
        .catch(error => {
          console.error(error)
          this.$store.commit("snackbar", {
              texto: `La propiedad ${propiedadTitulo} no pudo ser eliminada.`,
              tipo: "error",
              icon: "mdi-bug"
          });
          this.propiedadEliminar = null
        })
    }
  },
  components: {
    confirmarEliminar
  }
}
</script>
