<template>
    <v-container grid-list-xs text-xs-center text-sm-left v-if="propiedad && propiedad.id">
      <v-row>
        <v-toolbar color="accent lighten-1" dense class="mb-4">
            <v-toolbar-title >{{ propiedad.titulo }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items v-if="user && user.name">
              <v-btn text  :to="{ name: 'PropiedadEditar', params: { id: propiedad.id } }">
                <v-icon>mdi-pencil</v-icon>
                Editar
              </v-btn>
            </v-toolbar-items>
        </v-toolbar>
      </v-row>

        <v-layout row wrap >
            <!-- <v-card>

            </v-card> -->
            <v-flex xs12>

            </v-flex>
            <v-flex xs12 md7>
                <propiedad-foto-carousel :propiedad="propiedad"></propiedad-foto-carousel>

                <v-layout row wrap >
                    <v-flex xs6>
                        <v-list-item class="grow">
                            <v-layout>
                                <span v-for="icono in iconos"
                                    :key="icono.nombre">
                                    <v-icon class="mr-1" :title="icono.texto">{{ icono.icono }}</v-icon>
                                    <span class="subheading mr-2">{{ icono.cantidad }}</span>
                                </span>
                            </v-layout>
                        </v-list-item>
                    </v-flex>
                </v-layout>

            </v-flex>
            <v-flex xs12 md4 ml-4>
                <v-card ma-2>
                    <v-card-title primary-title>
                        {{ propiedad.detalle }}
                    </v-card-title>
                    <div v-if="propiedad.latitud && propiedad.longitud" class="google-maps">

                        <iframe width="600" height="200" frameborder="0" style="border:0"
                            :src="'https://www.google.com/maps/embed/v1/view?&center='
                                + propiedad.latitud+','+ propiedad.longitud
                                + `&zoom=${propiedad.ubicacion_zoom || 17}&maptype=satellite`
                                + '&key=AIzaSyB5T_wPG8jnGCJ5xwZIvLYVu5VHRaR1uAM'"
                            allowfullscreen>

                        </iframe>
                    </div>
                    <v-card-text class="px-0">
                        <ul class="subheading">
                            <li v-for="caracteristica in caracteristicasTexto" :key="caracteristica.id">
                                {{ caracteristica.texto }}
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from "axios"
import propiedadesApi from '../api/propiedades'
import propiedadFotoCarousel from '../components/propiedad/PropiedadFotoCarousel'

export default {
    props: ['id'],
    data() {
        return {

            propiedad: {},
            error: null
        }
    },
    beforeCreate(){
        // console.log('beforeCreate')
    },
    created() {
        // console.log('create')

        this.obtenerPropiedad()
    },
    beforeMount() {
        // console.log('beforeMount')
    },
    mounted(){
        // console.log('mounted')
    },
    beforeUpdate(){
        // console.log('beforeUpdate')
    },
    updated() {
        // console.log('updated')
    },
    methods: {
        obtenerPropiedad() {
            propiedadesApi.show(this.id)
                .then(response => {
                    this.propiedad = response.data
                })
                .catch(error => {
                    this.error = error.message
                })
        }
    },
    computed: {
      caracteristicasTexto() {
        return (this.propiedad.caracteristicas
          .filter(caracteristica => caracteristica.texto)
          .sort((a, b) => a.orden - b.orden))
      },
      iconos( ) {
        return (this.propiedad.caracteristicas
          .filter(caracteristica => caracteristica.icono)
          .sort((a, b) => a.orden - b.orden))
      },
      user() {
        return this.$store.state.usuario;
      },
    },
    components: {
        propiedadFotoCarousel
    },
    metaInfo() {
        return {
            title: `${ this.propiedad && this.propiedad.titulo }`,
            meta: [
                { property: 'og:title', content: `${ this.propiedad && this.propiedad.titulo }` },
                { name: 'og:description', content: `${ this.propiedad && this.propiedad.detalle }` },
                { property: 'og:image', content: `${ this.propiedad && this.propiedad.imagen }` }
            ]
        }
    }
}
</script>


<style>
    .estado {
        text-align: center;
        font-size: 2vw;
        font-weight: bold;
        color: red;
        text-transform: uppercase;
        background-color: #fae4219d;
        padding: 0.2em 0.3em;

        position: absolute;
        top: 30%;
        left: 25%;
        transform: translate(-30%, -25%);

    }

    .rotate {
        transform: rotate(-45deg);
    }

    .google-maps {
        position: relative;
        padding-bottom: 75%;
        height: 0;
        overflow: hidden;
    }

    .google-maps iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
</style>
