<template>
<v-dialog
    v-model="dialog"
    width="500"
>
    <v-card>
    <v-card-title class="text-h5 accent">
        Eliminar {{ descripcion }}
    </v-card-title>

    <v-card-text>
        <br>
        ¿Está seguro que desea eliminar {{ descripcion }}?
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>

        <v-spacer></v-spacer>

        <v-btn
        color="danger"
        text
        @click="cancelarEliminar"
        >
            Cancelar
        </v-btn>

        <v-btn
        color="primary"
        text
        @click="confirmarEliminar"
        >
            Confirmar
        </v-btn>
    </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
    props: ['elementoEliminar', 'descripcion'],
    data() {
        return {
            dialog: false,
        }
    },
    created() {
        this.dialog = !!this.elementoEliminar
    },
    watch: {
        elementoEliminar(val) {
            this.dialog = !!val
        }
    },
    methods: {
        confirmarEliminar() {
            this.$emit('confirmarEliminar')
        },
        cancelarEliminar() {
            this.$emit('cancelarEliminar')
        }
    }
}
</script>

<style>

</style>
