<template>
    <v-carousel>
        <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            :src="item.src">
        </v-carousel-item>
    </v-carousel>
</template>

<script>
export default {
    data () {
        return {
            items: [
                // {
                //     src: 'assets/img/carousel/20161231-galpon.jpg'
                // },
                {
                    src: 'https://i1.wp.com/inmobiliariacedrani.com.uy/wp-content/uploads/2016/12/IMG_20161231_122653447.jpg?fit=2592%2C1456'
                },
                {
                    src: 'https://i2.wp.com/inmobiliariacedrani.com.uy/wp-content/uploads/2018/05/silde4Oct.png?fit=1080%2C443'
                },
                {
                    src: 'https://i0.wp.com/inmobiliariacedrani.com.uy/wp-content/uploads/2018/02/casaZonaHospial_2_slide.jpg?fit=1080%2C443'
                }
            ]
        }
    }
}
</script>

<style>

</style>
