import axios from 'axios'

export default {

    login(credenciales) {
        return axios.get('/sanctum/csrf-cookie')
            .then(() => {
                return axios.post('/login', credenciales)
            });
    },
    signup(datos) {
        return axios.post('/api/auth/registro', datos)
    },
    user() {
        return axios.get('/api/usuario')
    },
    logout() {
        return axios.get('/sanctum/csrf-cookie')
            .then(() => { return axios.post('/logout') });
    },
    getCSRFToken() {
        return axios.get('/sanctum/csrf-cookie')
    }
}
