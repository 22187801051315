import axios from 'axios'

let apiPath = '/api/admin/propiedades'

export default {
    all() {
        return axios.get(`${apiPath}`)
    },
    store(data) {
      return axios.post(apiPath, data)
    },
    show(id) {
        return axios.get(`${apiPath}/${id}`)
    },
    update(id, data) {
        data.append("_method", "put");
        return axios.post(`${apiPath}/${id}`, data)
    },
    destroy(id) {
        return axios.delete(`${apiPath}/${id}`)
    },
    getEstados() {
      return axios.get(`${apiPath}/estados`)
    }
}
