import { dataURItoBlob } from '../helpers/imagenHelper'

let propiedad = () => {
  return {
    id: 0,
    publicada: true,
    titulo: '',
    detalle: '',
    fecha: '',
    categoria_id: 1,
    foto_principal_id: null,
    estado_id: 1,
    venta: true,
    alquiler: false,
    longitud: 0,
    latitud: 0,
    ubicacion_zoom: 18,
  }
}

function getPropiedadFormData(propiedad) {
  let formData = new FormData()
  formData.append('id', propiedad.id)
  formData.append('publicada', propiedad.publicada ? 1 : 0)
  formData.append('titulo', propiedad.titulo)
  formData.append('detalle', propiedad.detalle)
  formData.append('fecha', propiedad.fecha)
  formData.append('categoria_id', propiedad.categoria_id)
  formData.append('foto_principal_id', propiedad.foto_principal_id)
  formData.append('estado_id', propiedad.estado_id || '')
  formData.append('venta', propiedad.venta ? 1 : 0)
  formData.append('alquiler', propiedad.alquiler ? 1 : 0)
  formData.append('longitud', propiedad.longitud)
  formData.append('latitud', propiedad.latitud)
  formData.append('ubicacion_zoom', propiedad.ubicacion_zoom)

  propiedad.caracteristicas.forEach((caracteristica, i) => {
    caracteristica.id && formData.append(`caracteristicas[${i}][id]`, caracteristica.id );
    if (caracteristica.eliminar) {
      formData.append(`caracteristicas[${i}][eliminar]`, true);
    } else {
      formData.append(`caracteristicas[${i}][icono]`, caracteristica.icono || '');
      formData.append(`caracteristicas[${i}][cantidad]`, caracteristica.cantidad || '');
      formData.append(`caracteristicas[${i}][texto]`, caracteristica.texto || '');
      formData.append(`caracteristicas[${i}][orden]`, caracteristica.orden || '');
    }
  });

  propiedad.fotos.forEach((foto, i) => {
      formData.append(`fotos[${i}][id]`, foto.id);
      formData.append(`fotos[${i}][nombre]`, foto.nombre);
      if (foto.eliminar) {
        formData.append(`fotos[${i}][eliminar]`, foto.eliminar);
      } else {
        if (foto.imagen) {
          formData.append(`fotos[${i}][imagen]`, dataURItoBlob(foto.imagen))
        }
        if (foto.principal) {
          formData.append(`fotos[${i}][foto_principal]`, true);
        }
      }
  })

  return formData
}

export {
  propiedad,
  getPropiedadFormData
}

export default {
  propiedad,
  getPropiedadFormData
}
