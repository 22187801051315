<template>
    <v-hover>
        <v-card
            slot-scope="{ hover }"
            :class="`elevation-${hover ? 12 : 2}`"
            :to="{ name: 'Propiedad', params: { id: propiedad.id } }">
            <v-img
                class="white--text"
                :class="'estado-' + propiedad.estado"
                height="220px"
                :src="fotoPrincipal"

            >
                <div class="text-right ma-2 " >
                    <v-chip color="accent indigo--text" v-if="propiedad.alquiler">alquiler</v-chip>
                    <v-chip color="accent indigo--text" v-if="propiedad.venta || !propiedad.alquiler">
                      venta
                    </v-chip>
                </div>
                <span class="estado rotate" v-if="propiedad.estado">
                    {{ propiedad.estado.nombre }}
                </span>
            </v-img>

            <v-card-title primary-title class="popiedad-title">
                <h3 class=" headline mb-0">
                    {{propiedad.titulo}}
                </h3>
            </v-card-title>

            <v-card-text>
                {{propiedad.detalle}}
            </v-card-text>

            <v-card-text>
                <v-list-item class="grow">
                    <v-layout
                    align-center
                    justify-end
                    >
                        <span v-for="icono in iconos"
                            :key="icono.id">
                            <v-icon class="mr-1" :title="icono.texto">{{ icono.icono }}</v-icon>
                            <span class="subheading mr-2" v-if="icono.cantidad" >{{ icono.cantidad }}</span>
                        </span>
                    </v-layout>
                </v-list-item>
            </v-card-text>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    props: ['propiedad'],
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {
      fotoPrincipal() {
        if (this.propiedad.fotos && this.propiedad.fotos.length > 0) {
          return this.propiedad.fotos[0].path;
        } else {
          return '/img/mariocedrani_01.jpg';
        }
      },
      iconos() {
        return (this.propiedad.caracteristicas
          .filter(c => c.icono && c.icono != "null")
          .sort((a, b) => a.orden - b.orden))
      }
    }
}
</script>

<style>
  .popiedad-title {
    word-break: unset;
  }

    .estado {
        text-align: center;
        font-size: 2vw;
        font-weight: bold;
        color: red;
        text-transform: uppercase;
        background-color: #fae4219d;
        padding: 0.2em 0.3em;

        position: absolute;
        top: 30%;
        left: 25%;
        transform: translate(-30%, -25%);

    }

    .rotate {
        transform: rotate(-45deg);
    }
</style>
