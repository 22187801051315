import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../tools/store'

Vue.use(VueRouter)

import Inicio from '../views/Inicio'
import Nosotros from '../views/Nosotros.vue'
import Propiedad from '../views/Propiedad.vue'
import IniciarSesion from '../views/auth/login.vue'
import Administrar from '../views/admin/administrar.vue'
import PropiedadCrear from '../views/admin/propiedadCrear.vue'
// import PropiedadEditar from '../views/admin/propiedadEditar.vue'
// import NotFound from '../views/404'

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/categoria/:categoria',
    name: 'Categoria',
    component: Inicio,
    props: true
  },
  {
    path: '/propiedades/:id',
    name: 'Propiedad',
    component: Propiedad,
    props: true
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: Nosotros
  },
  {
    path: '/administrar/iniciar-sesion',
    name: 'IniciarSesion',
    component: IniciarSesion
  },
  {
    path: '/administrar',
    name: 'Administrar',
    component: Administrar
  },
  {
    path: '/administrar/propiedades/crear',
    name: 'PropiedadCrear',
    component: PropiedadCrear
  },
  {
    path: '/administrar/propiedades/editar/:id',
    name: 'PropiedadEditar',
    component: PropiedadCrear,
    props: true
  }
]


const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

router.beforeEach(function (to, from, next) {
  let panelAdmin = to.path.startsWith("/administrar")

    if (!panelAdmin || (to.params && to.params.nocheck)) {
        next()
    } else {
        store.dispatch('estaLogueado', {
          ok(r) {
              console.log('estaLogueado')
                if (to.name === 'Ingresar') {
                    store.commit('finCarga')
                    next({
                        name: 'Inicio',
                        query: null,
                        params: {
                            nocheck: true
                        }
                    })
                }
                document.title = 'Inmobiliaria Mario Cedrani'
                next()
            },
            err(r) {
              console.log('no estaLogueado', to.path)
              if (panelAdmin) {
                console.log('la ruta es de administracion')
                document.title = 'Inmobiliaria Mario Cedrani - Inicio de sesión'
                store.commit('finCarga')
                next({
                    name: 'IniciarSesion',
                    params: {
                        nocheck: true
                    }
                })
              } else {
                console.log('la ruta no es de administracion')
                next()
              }
            }
        })
    }
})

export default router
