<template>
    <v-container grid-list-xl >
        <v-layout row wrap>
            <v-flex xs12 md3 v-for="propiedad in propiedadesFiltradas" :key="propiedad.id">
                <propiedad-item-list :propiedad="propiedad" />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from "axios"
import propiedadesApi from "../api/propiedades.js"
import propiedadItemList from "../components/propiedad/PropiedadItemList"

export default {
    props: ["categoria"],
    data() {
        return {
            propiedades: [],
            error: null
        }
    },
    created() {
        this.obtenerPropiedades()
    },
    computed: {
      propiedadesFiltradas() {
        return this.propiedades.filter(propiedad => {
          return (this.categoria != null ? propiedad.categoria_id == this.categoria : true)
        })
        .sort((a, b) => {
          return this.getOrden(b) - this.getOrden(a)
        })
      }
    },
    methods: {
        obtenerPropiedades() {
            // this.categoria = Number(this.$route.params.categoria)
            if (this.propiedades && this.propiedades.length > 0) {
              return
            }

            propiedadesApi.all()
            .then(response => {
                this.propiedades = response.data
            })
            .catch(error => {
                this.error = (error.response && error.response.data.message)
                    || error.message
            })

        },
        getOrden(propiedad) {
            let puntaje = 0
            puntaje = propiedad.fotos && propiedad.fotos.length > 0 ? puntaje + 2 : puntaje - 2
            puntaje = propiedad.estado ? puntaje - 1 : puntaje + 1
            return puntaje
        }
    },
    watch: {
        '$route.params.categoria' () {
            this.obtenerPropiedades()
        }
    },
    components: {
        'propiedad-item-list': propiedadItemList
    }
}
</script>
