import Vue from 'vue'
import Vuex from 'vuex'
import authApi from '../../api/auth'
// import { get } from 'https'
// import axios from 'axios'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        auth: false,
        usuario: {
            name: '',
            email: ''
        },
        token: null,
        cargando: false,
        snackbar: {
            activo: false,
            tipo: '',
            timeout: 6000,
            icon: null,
            texto: '',
            color: "info",
            mode: "",
            x: null,
            y: "top"
        },
        zonaAdmin: false,
    },
    mutations: {
        auth(state, authStatus) {
            state.auth = authStatus
        },
        usuario(state, usuario) {
            state.usuario = usuario
        },
        zonaAdmin(state, zonaAdmin) {
            state.zonaAdmin = zonaAdmin
        },
        logout(state, auth) {
            state.auth = null
            state.usuario = {
                name: '',
                email: ''
            }
        },
        inicioCarga(state) {
            state.cargando = true
        },
        finCarga(state) {
            state.cargando = false
        },
        snackbar(state, snackbar) {
            state.snackbar.activo = true
            state.snackbar.tipo = snackbar.tipo || 'info'
            state.snackbar.timeout = snackbar.timeout || state.snackbar.timeout
            state.snackbar.icon = snackbar.icon || 'mdi-information'
            let texto = ''
            if (Array.isArray(snackbar.texto)) {
                snackbar.texto.forEach(t => { texto += '\n' + t })
            } else if (typeof snackbar.texto === 'string') {
                texto = snackbar.texto
            }
            state.snackbar.texto = texto
        },
        snackbarClose(state) {
            state.snackbar.activo = false
            state.snackbar.tipo = ''
            state.snackbar.timeout = 6000
            state.snackbar.icon = null
            state.snackbar.texto = ''
        }
    },
    actions: {
        login({ commit, dispatch }, { cred, ok, err }) {

            authApi.login(cred)
                .then(response => {
                    // authApi.setToken(response.data.access_token)
                    commit('auth', true)
                    ok(true)
                })
                .catch(error => {
                    err(error.response.data)
                })
        },
        estaLogueado({ commit, dispatch, getters }, { ok, err }) {
            // let token = localStorage.getItem('token_key')
            if (getters.auth) {
                ok('El usuario esta logueado!')
            }
            else {
                authApi.user()
                    .then(response => {
                        commit('auth', true)
                        commit('usuario', response.data)
                        ok('El usuario esta logueado!')
                    })
                    .catch(error => {
                        commit('auth', false)
                        commit('usuario', {})
                        err('Error el usuario no esta logueado!')
                    })
            }
        },
        logout({ commit, dispatch }, { ok }) {
            authApi.logout()
                .then()
                .finally(() => {
                    commit('auth', false)
                    commit('usuario', {})
                    ok()
                })
        }
    },
    getters: {
        auth(state) {
            return state.auth
        },
        cargando(state) {
            return state.cargando
        },
        snackbar(state) {
            return state.snackbar
        },
        token (state) {
            return state.token
        },
        zonaAdmin(state) {
            return state.zonaAdmin
        },
        usuario(state) {
            return state.usuario
        }
    }
})

export default store
