<template>
  <v-layout row align-center justify-center  class="fondo">
    <v-flex xs11 md6 lg4 xl3>
      <v-layout row style="margin-bottom: 16px">
        <v-flex>
          <form @submit.prevent="iniciar">
            <v-card class="my-12" >
              <v-card-title class="accent">
                  <img src="/img/logo_Mario_Cedrani.png" class="logo-login" align-center justify-center alt="Inmobiliaria Mario Cedrani" />
              </v-card-title>
              <v-layout column pa-4>
                <v-flex>
                  <v-text-field
                    id="usuario"
                    label="Usuario"
                    type="text"
                    name="user"
                    v-model="auth.user"
                    class="login"
                  />
                </v-flex>
                <v-flex>
                  <v-text-field
                    id="password"
                    label="Contraseña"
                    type="password"
                    name="pass"
                    v-model="auth.pass"
                    class="login"
                  />
                </v-flex>
              </v-layout>
              <v-card-actions >
                <!-- <v-switch
                  id="recordarme"
                  label="Recordarme"
                  v-model="auth.recordar"
                  name="recordar"
                  hide-details
                ></v-switch> -->
                <!-- <v-spacer /> -->
                <v-btn type="submit" :disabled="iniciarSesionDesactivado" color="primary" class="mx-auto text-center" :loading="iniciando">
                  Iniciar Sesión
                  <v-icon>mdi-badge-account</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </form>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      iniciando: false,
      auth: {
        user: "",
        pass: "",
        recordar: false
      }
    };
  },
  methods: {
    iniciar() {
      let self = this; // Por que el uso de self?
      self.iniciando = true;
      let auth = {
        email: this.auth.user,
        password: this.auth.pass,
        remember_me: this.auth.recordar
      };
      self.$store.dispatch("login", {
        cred: auth,
        ok(response) {
          if (response) {
            let redirect = { name: "Inicio" };
            self.$router.push(redirect);
          } else {
            self.showError("error iniciando sesion...")
            this.showError("Error iniciando sesion.")
          }
        },
        err(error) {
          self.showError(`Error iniciando sesion: ${error.message}`)
        }
      });
    },
    showError(errorMsg) {
        this.iniciando = false;
        console.error("error iniciando sesion:", errorMsg);
          this.$store.commit("snackbar", {
            texto: 'Credenciales incorrectas.',
            tipo: "error",
            icon: "error"
        });
    }
  },
  computed: {
    iniciarSesionDesactivado() {
      return !this.auth.user || !this.auth.pass;
    }
  }
};
</script>

<style>
.fondo {
  height: 100%;
  padding: 0;
  margin: 0;
}

.fondo::before {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  content: "";
  opacity: 0.6;

  background: url("/img/login-bk.jpg") no-repeat center center fixed;
  /* filter: blur(5px); */
  background-color: rgb(255, 236, 252);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.logo-login {
  width: 200px;
  padding: 0;
  margin-left: 25%;
}
</style>
