<template>
  <v-container >
    <v-toolbar  color="accent" dense>
      <v-toolbar-title>
        <v-icon>mdi-home</v-icon> {{ id ? 'Editar' : 'Crear' }}  Propiedad
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text color="secondary" :to="{ name: 'Administrar' }" exact>
          <v-icon>mdi-close</v-icon>
          Cancelar
        </v-btn>
        <v-btn text color="secondary" @click="guardarPropiedad" :disabled="guardando" :loading="guardando">
          <v-icon>mdi-floppy</v-icon>
          Guardar
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-container>
      <v-form v-model="valida" ref="form">
        <v-card>
          <v-card-text class="d-flex flex-no-wrap">
            <v-container >
              <v-row>
                <v-col>
                  <v-checkbox dense class="mx-4" v-model="propiedad.venta" label="Venta" />
                </v-col>

                <v-col>
                  <v-checkbox dense class="mx-4" v-model="propiedad.alquiler" label="Alquiler" />
                </v-col>

                <v-col>
                  <v-autocomplete
                    :items="estados"
                    dense
                    label="Estado"
                    v-model="propiedad.estado_id"
                    item-text="nombre"
                    item-value="id"
                    clearable
                    />

                </v-col>
              </v-row>


            <v-row>
              <v-col md="6">
                <v-text-field
                    dense
                    v-model="propiedad.titulo"
                    :rules="reglaTextoRequerido"
                    label="Titulo"
                    required
                ></v-text-field>
              </v-col>

              <v-col md="3">
                <v-autocomplete
                    dense
                    v-model="propiedad.categoria_id"
                    :items="categorias"
                    search-input="nombre"
                    item-value="id"
                    item-text="nombre"
                    label="Categoría"
                ></v-autocomplete>
              </v-col>

              <v-col md="3">
                <v-switch dense v-model="propiedad.publicada" label="Publicada" />
              </v-col>
            </v-row>

            <v-row>
              <v-col >
                <v-textarea
                    dense
                    v-model="propiedad.detalle"
                    label="Comentario"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="12">
                <v-toolbar dense color="accent" class="mb-4">
                  <v-toolbar-title >
                    <v-icon>mdi-ticket</v-icon> Características
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </v-col>

              <v-col>
                <v-row>
                  <v-col class="align-center" v-if="caracteristicas.length">
                    <v-btn @click="agregarCaracteristica(true)" text color="secondary" >
                        Agregar característica al inicio
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-for="(caracteristica, index) in caracteristicas"
                :key="caracteristica.hash">
                  <v-col sm="1">
                    <v-icon v-if="index != 0"
                      @click="() => { caracteristicasBajarOrden(caracteristica, index) }">
                      mdi-arrow-up
                    </v-icon>

                    <v-icon v-if="index != (caracteristicas.length -1)"
                      @click="() => { caracteristicasSubirOrden(caracteristica, index)  }">
                      mdi-arrow-down
                    </v-icon>
                  </v-col>
                  <v-col sm="2">
                    <v-autocomplete
                      v-model="caracteristica.icono"
                      :items="iconos"
                      dense
                      color="blue-grey lighten-2"
                      label="Icono"
                      clearable
                    >

                      <template v-slot:selection="data">
                        <v-icon>{{data.item}}</v-icon>
                      </template>

                      <template v-slot:item="data">
                        <v-icon>{{data.item}}</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col sm="2">
                    <v-text-field
                      dense
                      v-model="caracteristica.cantidad"
                      label="Cantidad"
                      v-if="caracteristica.icono"
                      >

                    </v-text-field>
                  </v-col>

                  <v-col sm="6">
                    <v-text-field
                        dense
                        v-model="caracteristica.texto"
                        label="Texto"
                    ></v-text-field>
                  </v-col>
                  <v-col sm="1">
                    <v-btn icon color="red" @click="eliminarCaracteristica(caracteristica, index)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="align-center">
                    <v-btn @click="agregarCaracteristica()" text color="secondary" >
                        Agregar característica <span v-if="caracteristicas.length">al final</span>
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="12">
                <v-toolbar dense color="accent" class="mb-4">
                  <v-toolbar-title >
                    <v-icon>mdi-camera</v-icon> Fotos
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn @click="agregarFoto()" text color="secondary" >
                        Agregar foto
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>

              <v-col v-for="(foto, fotoKey) in fotos"
                      :key="foto.hash" md="4">
                  <v-card  flat >
                      <v-card-text>
                          <v-btn style="float: right" icon @click="() => { eliminarFoto(fotoKey) }">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <drop-image
                              :imagen-index="fotoKey"
                              :tipo="'foto'"
                              v-on:nuevaImagen="setearFoto"
                              :foto-url="foto.path"
                          />
                      </v-card-text>
                  </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col sm="12">
                <v-toolbar dense color="accent" class="mb-4">
                  <v-toolbar-title >
                    <v-icon>mdi-map</v-icon> Mapa
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </v-col>

              <v-col md="4">
                <v-text-field
                    dense
                    v-model="propiedad.latitud"
                    label="Latitud"
                ></v-text-field>
              </v-col>

              <v-col md="4">
                <v-text-field
                    dense
                    v-model="propiedad.longitud"
                    label="Longitud"
                ></v-text-field>
              </v-col>

              <v-col md="4">
                <v-slider
                  v-model="propiedad.ubicacion_zoom"
                  :label="`zoom (${propiedad.ubicacion_zoom})`"
                  hint="Zoom del mapa"
                  max="19"
                  min="8"
                  prepend-icon="mdi-magnify"
                ></v-slider>
              </v-col>

              <v-col sm="12" v-if="propiedad.latitud && propiedad.longitud">
                <div class="google-maps-edit">
                    <iframe width="600" height="200" frameborder="0" style="border:0"
                        :src="'https://www.google.com/maps/embed/v1/place?&q='
                            + propiedad.latitud+','+ propiedad.longitud
                            + `&zoom=${propiedad.ubicacion_zoom || 17}&maptype=satellite`
                            + '&key=AIzaSyB5T_wPG8jnGCJ5xwZIvLYVu5VHRaR1uAM'"
                        allowfullscreen>

                    </iframe>
                </div>
              </v-col>
            </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { getPropiedadFormData } from '../../model/propiedad'
import propiedadesApi from '../../api/adminPropiedades'
import categoriasApi from '../../api/adminCategorias'
import dropImage from './componentes/dropImage'

export default {
  props: ['id'],
  data() {
    return {
      propiedad: {
        id: 0,
        publicada: true,
        titulo: '',
        detalle: '',
        fecha: '',
        categoria_id: 1,
        fotos: [],
        foto_principal_id: null,
        caracteristicas: [],
        estado_id: null,
        venta: true,
        alquiler: false,
        longitud: 0,
        latitud: 0,
        ubicacion_zoom: 18,
      },
      caracteristicas: [],
      caracteristicasEliminar: [],
      valida: true,
      reglaTextoRequerido: [
        v => !!v || "El campo es requerido"
      ],
      categorias: [],
      estados: [],
      iconos: [
        'mdi-bed',
        'mdi-car',
        'mdi-shower-head',
        'mdi-ruler-square',
        'mdi-leaf-maple',
        'mdi-store',
        'mdi-fridge',
        'mdi-sofa',
        'mdi-air-conditioner',
        'mdi-gas-burner',
      ],
      guardando: false,
    }
  },
  beforeCreate() {

  },
  created() {
    this.cargarCategorias()
    this.cargarEstados()
    if (this.id) {
      propiedadesApi.show(this.id)
        .then(response => {
          let propiedad = response.data
          propiedad.caracteristicas = Array.isArray(propiedad.caracteristicas) ? propiedad.caracteristicas : []
          for (let index = 0; index < propiedad.caracteristicas.length; index++) {
            if (!propiedad.caracteristicas[index].orden) {
              propiedad.caracteristicas[index].orden = index
            }
          }
          this.caracteristicas = propiedad.caracteristicas.sort((a, b) => a.orden - b.orden)
          this.propiedad = propiedad
        })
    } else {
      this.agregarFoto()
      this.agregarCaracteristica()
    }
  },
  computed: {
    fotos() {
      return this.propiedad.fotos.filter(f => !f.eliminar)
    }
  },
  methods: {
    guardarPropiedad() {
      this.guardando = true
      if (this.$refs.form.validate()) {
        for (let index = 0; index < this.caracteristicas.length; index++) {
          this.caracteristicas[index].orden = index + 1
        }
        this.propiedad.caracteristicas = this.caracteristicas
        this.propiedad.caracteristicas.push(...this.caracteristicasEliminar)
        console.log(this.propiedad.caracteristicas)
        let propiedadFormData = getPropiedadFormData(this.propiedad)
        let proceso = this.id ? propiedadesApi.update(this.id, propiedadFormData) : propiedadesApi.store(propiedadFormData)
        proceso
          .then(response => {
            this.$router.push({ name: 'Propiedad', params: { id: response.data.id } })
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.guardando = false
          })
      } else {
        this.guardando = false
      }
    },
    cargarCategorias() {
      categoriasApi.all()
        .then(result => {
          this.categorias = result.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    cargarEstados() {
      propiedadesApi.getEstados()
        .then(result => {
          this.estados = result.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    agregarFoto() {
      this.propiedad.fotos.push({
          id: null,
          hash: new Date().toISOString(),
          nombre: null,
          imagen: null
      })
    },
    agregarCaracteristica(unshift = false) {
      let caracteristica = {
          id: null,
          hash: new Date().toISOString(),
          icono: "",
          cantidad: "",
          texto: "",
          orden: this.caracteristicas.length + 1
      }
      if (unshift) {
        this.caracteristicas.unshift(caracteristica)
      } else {
        this.caracteristicas.push(caracteristica)
      }
    },
    setearFoto(img, nombre, fotoKey) {
      this.propiedad.fotos[fotoKey].imagen = img
      this.propiedad.fotos[fotoKey].nombre = nombre
    },
    eliminarCaracteristica(caracteristica, caracteristicaKey) {
      if (caracteristica.id) {
        console.log('caracteristica.eliminar = true', caracteristica, caracteristicaKey)
        caracteristica.eliminar = true
        this.caracteristicasEliminar.push(caracteristica)
      }

      this.caracteristicas.splice(caracteristicaKey, 1)
    },
    eliminarFoto(fotoKey) {
      if (this.propiedad.fotos[fotoKey].id) {
        this.propiedad.fotos[fotoKey].eliminar = true
        this.propiedad.fotos.splice(fotoKey, 1, this.propiedad.fotos[fotoKey])
      } else {
        this.propiedad.fotos.splice(fotoKey, 1)
      }
    },
    caracteristicasSubirOrden(caracteristica, index) {
      this.caracteristicas.splice(index, 1)
      this.caracteristicas.splice(index+1, 0, caracteristica)
    },
    caracteristicasBajarOrden(caracteristica, index) {
      this.caracteristicas.splice(index, 1)
      this.caracteristicas.splice(index-1, 0, caracteristica)
    }
  },
  components: {
    dropImage
  }
}
</script>

<style>
  .google-maps-edit {
        position: relative;
        padding-bottom: 25%;
        height: 0;
        overflow: hidden;
    }

    .google-maps-edit iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
</style>
