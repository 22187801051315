import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

// Translation provided by Vuetify (javascript)
import es from 'vuetify/lib/locale/es'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify)

const opts = {
    lang: {
        locales: { es },
        current: 'es'
    },
    theme: {
        themes: {
            light: {
                primary: colors.yellow.darken2,
                secondary: colors.blue.darken4,
                accent: colors.yellow,
                error: colors.red.accent3,
                warning: colors.yellow.accent3,
                info: colors.blue.accent3,
                success: colors.green.accent3
            },
            dark: {
                primary: colors.blue.lighten5,
                secondary: colors.blue.darken1,
                accent: colors.green,
                error: colors.red.accent3,
                warning: colors.yellow.accent3,
                info: colors.blue.accent3,
                success: colors.green.accent3
            }
        }
    }
}
export default new Vuetify(opts)
